import ExpandingIconButton from '@components/ExpandingIconButton/ExpandingIconButton';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import Icon from '@xo-union/tk-component-icons';
import classNames from 'classnames';
import React, { useState, type FC } from 'react';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../../../hooks/useTrackInlineForm/use-track-inline-form';
import TextareaField from '../TextareaField/TextareaField';
import Styles from './TextareaFieldWithGenerateMsgBtn.scss';

export type Props = {
	context: ReturnType<typeof useInlineRfqForm>;
	generatedMessage: string;
	isOnLiteRFQ?: boolean;
	setGeneratedMessage?: (message: string) => void;
	setInitialMessageValue?: (value: string) => void;
	onClick: () => void;
};

export const TextareaFieldWithGenerateMsgBtn: FC<Props> = ({
	context,
	generatedMessage,
	isOnLiteRFQ = false,
	setGeneratedMessage,
	setInitialMessageValue,
	onClick,
}) => {
	const trackButtonClick = useTrackInlineForm(
		'AI assistance start',
		context.initiator,
		`${context.isModalOpen ? 'modal' : 'inline'} ${isOnLiteRFQ ? 'lite RFQ' : 'RFQ'}`,
	);

	const onButtonClick = () => {
		trackButtonClick();
		setInitialMessageValue?.(context.values.textarea.value);
		onClick();
	};

	// Force trigger on first tap for mobile
	const isMobile = useAppSelector(selectIsMobile);
	const [forcedExpanded, setForcedExpanded] = useState(false);
	const [hasTappedTextarea, setHasTappedTextarea] = useState(false);
	const triggerExpansion = () => {
		if (isMobile) {
			setForcedExpanded(true);
			setTimeout(() => setForcedExpanded(false), 1000);
		}
	};

	const handleFirstTextareaClick = () => {
		if (!hasTappedTextarea) {
			setHasTappedTextarea(true);
			triggerExpansion();
		}
	};

	return (
		<>
			<div
				className={classNames(Styles.wrapper, {
					[Styles.notEmpty]: context.values.textarea.value,
				})}
				data-testid="textarea-with-generate-msg-btn"
			>
				<div
					onKeyDown={handleFirstTextareaClick}
					onClick={handleFirstTextareaClick}
				>
					<TextareaField
						context={context}
						generatedMessage={generatedMessage}
						setGeneratedMessage={setGeneratedMessage}
						isRequired
					/>
				</div>
				<ExpandingIconButton
					onClick={onButtonClick}
					colorScheme="primary"
					text="Generate message"
					IconComponent={<Icon name="ai_sparkle" size="md" />}
					forcedExpanded={forcedExpanded}
				/>
			</div>
		</>
	);
};
